import * as React from 'react';
import { graphql } from 'gatsby';
import { useMobile } from '../../../utils/use-series';

import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CLabelList,
  CSectTitle,
  CBanquetViewMedia,
  CFixedImg,
  CSimpleCard,
  LBanquetIncs,
  LVenue,
  LWifi,
  LBanquetContact,
  CFadeSlider,
  CSpSliderCard03,
} from '../../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const isSp = useMobile();

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        type="ja"
        data={{
          title: {
            main: (
              <>
                スカイバンケットルーム
                <br className="u_sp" />
                「オーロラ／レインボー」
              </>
            ),
            sub: 'ホテル棟 70F',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/banquet/sky_banquet/kv.png',
              },
              imgSp: {
                src: '/assets/images/banquet/sky_banquet/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: '宴会・会議',
                path: '/banquet/',
              },
            ],
            current: {
              label: 'スカイバンケットルーム「オーロラ／レインボー」',
            },
          }}
        />
      </CJumbotron>
      <section className="u_mbExlarge l_sect">
        <LWrap>
          <h2 className="c_headingLv2">
            スカイバンケットルーム
            <br className="u_sp" />
            「オーロラ／レインボー」
          </h2>
          <CLabelList
            data={[
              'パーティー',
              '会議・コンベンション',
              '講演・セミナー',
              'イベント・展示',
              '同窓会',
            ]}
          />
          <CSpSliderCard03
              exClass="u_mb80"
              col={3}
              data={[
                {
                  img: {
                    src: '/assets/images/banquet/sky_banquet/img_aurora_rainbow.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/sky_banquet/img_aurora_rainbow02.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/sky_banquet/img_aurora_rainbow03.png',
                    alt: '',
                  },
                },
              ]}
            />
          <p className="c_sectLead u_mb30">
            地上277ｍ、横浜ランドマークタワ ー最上階。
            <br />
            宴会場としては日本でもっとも高い場所に位置するスカイバンケットルームです。
            <br />
            富士山・野毛山公園・桜木町駅付近・丹沢山地・横浜市街地方面がご覧いただけます。
            <br />
            この唯一無二のロケーションがすばらしい演出効果となり、お客様へ忘れがたい思い出を提供することができます。
            <br />
            また控室2室とホワイエを含めたフロア貸切りでのご利用も可能です。
          </p>
          <CSpSliderCard03
              exClass="u_mb80"
              col={3}
              data={[
                {
                  img: {
                    src: '/assets/images/banquet/sky_banquet/img_aurora_rainbow04.png',
                    alt: '',
                  },
                  text: (
                    <>控室</>
                  ),
                },
                {
                  img: {
                    src: '/assets/images/banquet/sky_banquet/img_aurora_rainbow05.png',
                    alt: '',
                  },
                  text: (
                    <>ホワイエ</>
                  ),
                },
              ]}
            />
          {!isSp ? (
            <table className="c_fixedTable">
              <thead>
                <tr>
                  <th rowSpan={2}>広さ（㎡）</th>
                  <th colSpan={4}>人数</th>
                </tr>
                <tr>
                  <th>正餐</th>
                  <th>立食</th>
                  <th>スクール</th>
                  <th>シアター</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>200</td>
                  <td>100</td>
                  <td>150</td>
                  <td>120</td>
                  <td>160</td>
                </tr>
              </tbody>
            </table>
          ) : (
            <>
              <table className="c_fixedTable">
                <thead>
                  <tr>
                    <th>広さ（㎡）</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>200</td>
                  </tr>
                </tbody>
              </table>
              <table className="c_fixedTable">
                <thead>
                  <tr>
                    <th colSpan={2}>人数</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>正餐</th>
                    <td>100</td>
                  </tr>
                  <tr>
                    <th>立食</th>
                    <td>150</td>
                  </tr>
                  <tr>
                    <th>スクール</th>
                    <td>120</td>
                  </tr>
                  <tr>
                    <th>シアター</th>
                    <td>160</td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
          <CFixedImg
            exClass="u_mbLarge"
            width={1040}
            img={{
              src: '/assets/images/banquet/sky_banquet/img_aurora07.png',
              alt: '',
            }}
          />
          <CSectTitle
            title={{
              ja: 'レイアウト例',
              en: (
                <>
                  LAYOUT <br className="u_sp" />
                  EXAMPLE
                </>
              ),
            }}
          />
          <CSimpleCard
            exClass="u_mbExLarge"
            data={[
              {
                img: {
                  src: '/assets/images/banquet/sky_banquet/img_aurora08.png',
                  alt: '',
                },
                text: (
                  <>
                    （上）オーロラ：ブッフェ 150名
                    <br />
                    （下）レインボー：正餐 100名
                  </>
                ),
              },
              {
                img: {
                  src: '/assets/images/banquet/sky_banquet/img_aurora09.png',
                  alt: '',
                },
                text: (
                  <>
                    （上）オーロラ：スクール 120名
                    <br />
                    （下）レインボー：シアター 160名
                  </>
                ),
              },
            ]}
          />
          <CBanquetViewMedia
            data={[
              {
                imgList: [
                  {
                    img: {
                      src: '/assets/images/stay/img_view_sw.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/img_view_sw02.png',
                      alt: '',
                    },
                  },
                ],
                text: (
                  <>
                    空気の澄んだ日には富士山がご覧いただけます。夜景が綺麗な方向です。
                    （野毛山公園　桜木町駅付近　富士山　丹沢山地　横浜市街地）
                  </>
                ),
              },
              {
                imgList: [
                  {
                    img: {
                      src: '/assets/images/stay/img_view_nw.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/img_view_nw02.png',
                      alt: '',
                    },
                  },
                ],
                text: (
                  <>
                    横浜駅・みなとみらい21 地区がご覧いただける方向です。
                    （横浜市街地　横浜駅周辺　みなとみらい21地区　東京方面）
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section>
      <LBanquetIncs />
      <LVenue />
      <section className="u_mtMedium u_mbMedium">
        <LWrap>
          <ul className="c_noteList">
            <li>画像は全てイメージです。</li>
          </ul>
        </LWrap>
      </section>
      <LBanquetContact />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
